import React, { useState, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";
import { IonIcon } from "@ionic/react";
import { checkmarkCircleOutline, arrowForwardOutline } from "ionicons/icons";

import PageLayout from "../../layouts/PageLayout";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Subscription } from "model";
import Title from "components/Title";
import Button from "components/Button";
import api from "../../api";
import { tryCatch, getReasonString } from "../../utils";

import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";

interface Props {
  reason?: string;
}

export const ManageSubContainer: React.FC<Props> = ({ reason }) => {
  const { adapterId } = useParams<{ adapterId: string }>();
  const history = useHistory();
  const { t } = useTranslation("manageSub");
  const [stripeKey, setStripeKey] = useState<any>(null);
  const [stripePromise, setStripePromise] = useState<any>(null);
  const [clientSecret, setClientSecret] = useState<string>("");
  const [formStep, setFormStep] = useState<number>(1);
  const [termSelection, setTermSelection] = useState<string>("year");
  const [prices, setPrices] = useState<any>([]);
  const { adapter, subscriptions } = useSelector((state: RootState) => ({
    adapter: state.adapter.adapter,
    subscriptions: state.adapter.subscriptions,
  }));
  const [subscription, setSubscription] = useState<Subscription | null>(null);
  const reasonString = getReasonString(reason);
  const [selectedSub, setSelectedSub] = useState<any>(null);

  useEffect(() => {
    FirebaseAnalytics.logEvent("init_page", { page: "manage" });
    getConfiguration();
  }, []);

  useEffect(() => {
    if (stripeKey) {
      let newStripePromise = loadStripe(stripeKey);
      setStripePromise(newStripePromise);
    }
  }, [stripeKey]);

  useEffect(() => {
    if (adapterId) {
      let adapterSub = subscriptions.find(
        (sub: Subscription) => sub.adapter_id === adapterId
      );
      if (adapterSub) {
        setSubscription(adapterSub);
      }
    } else if (adapter && adapter.id) {
      let adapterSub = subscriptions.find(
        (sub: Subscription) => sub.adapter_id === adapter.id
      );
      if (adapterSub) {
        setSubscription(adapterSub);
      }
    }
  }, [adapterId, adapter, subscriptions]);

  const getConfiguration = async () => {
    const configRes = await tryCatch(api.subscription.getConfig());
    if (configRes.error) {
      console.warn(configRes.error.message);
    } else {
      if (configRes.result.data.publishable_key) {
        setStripeKey(configRes.result.data.publishable_key);
      }
      if (configRes.result.data.prices) {
        setPrices(configRes.result.data.prices);
      }
    }
  };

  const handleUpdateSubscription = async (
    subscription_id: string,
    price_id: string
  ) => {
    const apiResult = await tryCatch(
      api.subscription.changePlan(subscription_id, price_id)
    );

    if (apiResult.error) {
      console.warn(apiResult.error.message);
    } else {
      window.location.href = `/account`;
      console.log("changePlan returned " + apiResult.result.data.result);
    }
  };

  return (
    <PageLayout back={history.goBack}>
      <Title title={t("title")} className="mb-2" />
      {reason && (
        <div
          style={{ padding: "20px", display: "flex", justifyContent: "center" }}
        >
          <p style={{ whiteSpace: "pre-line", width: "80%" }}>
            {t(reasonString)}
          </p>
        </div>
      )}
      {prices &&
        prices.length > 0 &&
        stripePromise &&
        subscription &&
        !selectedSub && (
          <div>
            <div style={styles.formContainer}>
              <div style={styles.termContainer}>
                <div style={styles.termSelector}>
                  <div
                    style={{
                      ...styles.termHighlight,
                      left: termSelection === "month" ? "1px" : "106px",
                    }}
                  ></div>
                  <div
                    style={{
                      ...styles.termOption,
                      fontWeight: termSelection === "month" ? 500 : "normal",
                    }}
                    onClick={() => setTermSelection("month")}
                  >
                    Monthly
                  </div>
                  <div
                    style={{
                      ...styles.termOption,
                      fontWeight: termSelection === "year" ? 500 : "normal",
                    }}
                    onClick={() => setTermSelection("year")}
                  >
                    Yearly
                  </div>
                </div>
              </div>
              <div style={styles.productContainer}>
                {prices.map((price: any, i: number) => {
                  if (
                    price.recurring &&
                    price.recurring.interval === termSelection &&
                    (!reason ||
                      (reason &&
                        price.product.metadata[reason] &&
                        price.product.metadata[reason].toLowerCase() ===
                          "true"))
                  ) {
                    return (
                      <div
                        key={`${price.id}_${Date.now()}`}
                        style={styles.productCardContainer}
                      >
                        <div style={styles.productCard}>
                          <div style={styles.productTitle}>
                            {price.product.name}
                          </div>
                          <div style={styles.productSubTitle}>
                            {price.product.description}
                          </div>
                          <div style={styles.productPriceContainer}>
                            <div style={styles.productPrice}>{`$${Number(
                              price.unit_amount / 100
                            ).toFixed(2)}`}</div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column" as "column",
                              }}
                            >
                              <div
                                style={{
                                  color: "rgba(26,26,26,0.5)",
                                  fontSize: "12px",
                                }}
                              >
                                per
                              </div>
                              <div
                                style={{
                                  color: "rgba(26,26,26,0.5)",
                                  fontSize: "12px",
                                }}
                              >
                                {termSelection}
                              </div>
                            </div>
                          </div>
                          <div style={{ marginBottom: "20px", width: "100%" }}>
                            <Button
                              data-testid="submit"
                              className="text-center mr-3 w-full"
                              size="small"
                              type="button"
                              style={{ width: "100%" }}
                              onClick={() => {
                                setSelectedSub(price);
                              }}
                              disabled={
                                price.product.name ===
                                  subscription.product_name &&
                                price.recurring.interval ===
                                  subscription.plan_interval
                              }
                            >
                              {price.product.name ===
                                subscription.product_name &&
                              price.recurring.interval ===
                                subscription.plan_interval
                                ? "Your Current Plan"
                                : t("subscribe")}
                            </Button>
                          </div>
                          {price.product.features.length > 0 && (
                            <div style={{ width: "100%" }}>
                              <div
                                style={{
                                  ...styles.featureListItem,
                                  marginBottom: "10px",
                                }}
                              >
                                This includes:
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column" as "column",
                                  gap: "5px",
                                }}
                              >
                                {price.product.features.map(
                                  (feature: any, i: number) => (
                                    <div
                                      key={`${i}_${Date.now()}`}
                                      style={{ display: "flex", gap: "5px" }}
                                    >
                                      <div>
                                        <IonIcon
                                          icon={checkmarkCircleOutline}
                                          style={{
                                            color: "rgba(26,26,26,0.5)",
                                          }}
                                          aria-hidden="true"
                                        />
                                      </div>
                                      <div style={styles.featureListItem}>
                                        {feature.name}
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </div>
          </div>
        )}
      {selectedSub && subscription && (
        <div style={styles.formContainer}>
          <div style={{ alignSelf: "flex-start" }}>
            <Button
              data-testid="submit"
              className="text-center mr-3"
              size="small"
              type="button"
              disabled={false}
              onClick={() => {
                setSelectedSub(null);
              }}
            >
              Back
            </Button>
          </div>
          <Title title={t("confirmChangeSub")} className="mb-2" />
          <div>{t("followingChange")}</div>
          <div style={styles.productContainer}>
            <div style={styles.changeSubContainer}>
              <div style={styles.productTitle}>{subscription.product_name}</div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row" as "row",
                  gap: "10px",
                }}
              >
                <div style={styles.productPrice}>{`$${Number(
                  subscription.plan_amount / 100
                ).toFixed(2)}`}</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column" as "column",
                  }}
                >
                  <div
                    style={{
                      color: "rgba(26,26,26,0.5)",
                      fontSize: "12px",
                    }}
                  >
                    per
                  </div>
                  <div
                    style={{
                      color: "rgba(26,26,26,0.5)",
                      fontSize: "12px",
                    }}
                  >
                    {subscription.plan_interval === "year" ? "Year" : "Month"}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <IonIcon
                icon={arrowForwardOutline}
                className="h-5 w-5 text-orange"
                aria-hidden="true"
              />
            </div>
            <div style={styles.changeSubContainer}>
              <div style={styles.productTitle}>{selectedSub.product.name}</div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row" as "row",
                  gap: "10px",
                }}
              >
                <div style={styles.productPrice}>{`$${Number(
                  selectedSub.unit_amount / 100
                ).toFixed(2)}`}</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column" as "column",
                  }}
                >
                  <div
                    style={{
                      color: "rgba(26,26,26,0.5)",
                      fontSize: "12px",
                    }}
                  >
                    per
                  </div>
                  <div
                    style={{
                      color: "rgba(26,26,26,0.5)",
                      fontSize: "12px",
                    }}
                  >
                    {selectedSub.recurring.interval === "year"
                      ? "Year"
                      : "Month"}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              paddingBottom: "20px",
              width: "600px",
              lineHeight: "150%",
            }}
          >
            {subscription.product_name === selectedSub.product.name &&
            selectedSub.product.name === "Privacy Premium"
              ? t("confirmationMessage")
              : t("confirmationMessageProrate")}
          </div>
          <div style={{ alignSelf: "center" }}>
            <Button
              data-testid="submit"
              className="text-center mr-3"
              size="small"
              type="button"
              disabled={false}
              onClick={() => {
                handleUpdateSubscription(
                  subscription.subscription_id,
                  selectedSub.id
                );
              }}
            >
              Change Subscription
            </Button>
          </div>
        </div>
      )}
    </PageLayout>
  );
};

const styles = {
  formContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    gap: "20px",
  },
  productContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row" as "row",
    flexWrap: "wrap" as "wrap",
    justifyContent: "center",
    gap: "10px",
  },
  productCardContainer: {
    width: "290px",
    height: "450px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  productCard: {
    width: "270px",
    height: "450px",
    padding: "10px",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "flex-start",
    alignItems: "center",
    transition: "0.4s",
    cursor: "pointer",
  },
  productTitle: {
    fontSize: "18px",
    fontWeight: 500,
    margin: "5px",
    width: "100%",
    marginBottom: "10px",
  },
  productSubTitle: {
    fontSize: "12px",
    width: "100%",
    color: "rgba(26,26,26,0.5)",
    height: "24px",
    marginBottom: "20px",
  },
  termContainer: {
    width: "100%",
    height: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  termSelector: {
    width: "220px",
    backgroundColor: "rgba(26,26,26,0.05)",
    border: "1px solid rgba(26,26,26,0.1)",
    borderRadius: "8px",
    height: "40px",
    display: "flex",
    position: "relative" as "relative",
    justifyContent: "center",
    alignItems: "center",
  },
  termHighlight: {
    width: "110px",
    height: "36px",
    backgroundColor: "rgb(253,162,63)",
    borderRadius: "6px",
    border: "1px solid rgba(26,26,26,0.05)",
    transition: "0.3s",
    position: "absolute" as "absolute",
  },
  termOption: {
    width: "100px",
    margin: "5px",
    textAlign: "center" as "center",
    transition: "0.6s",
    cursor: "pointer",
    zIndex: 5,
    fontSize: "14px",
    height: "100%",
    lineHeight: "100%",
    paddingTop: "12px",
  },
  productPriceContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: "5px",
    marginBottom: "20px",
  },
  productPrice: {
    fontWeight: 700,
    fontSize: "32px",
  },
  featureListItem: {
    fontSize: "14px",
  },
  changeSubContainer: {
    border: "1px solid #d3d3d3",
    borderRadius: "3px",
    display: "flex",
    flexDirection: "column" as "column",
    gap: "10px",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
  },
};

export default ManageSubContainer;
